import jsPDF from "jspdf";
import React from "react";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { Grid, Link, Paper } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import authoritySignature from "assets/images/authoritySignature.png";
import IfanFlexLogo from "assets/images/ifanFlexLogo.png";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoiceData, fetchTaxInvoiceData } from "../Redux/actionCreator";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import "./UserTaxInvoice.css";

const UserTaxInvoice = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { Invoice_Data, Invoice_Tax_Data } = useSelector(
    (state) => state.invoice
  );
  // var invoiceData = Invoice_Tax_Data?.invoiceTaxDataInfo;
  var invoiceTaxData = Invoice_Tax_Data?.invoiceTaxDataInfo;
  console.log(">>>", invoiceTaxData);
  // const [responseData, setResponseData] = useState(invoiceData);

  const path = window.location.pathname.split("view/")[1];
  useEffect(() => {
    dispatch(fetchTaxInvoiceData(path));
  }, [path, dispatch]);

  // async function processInvoiceData(invoiceData) {
  //   const invoiceName = invoiceData?.user?.name;
  //   // console.log("1--->", invoiceName);
  //   const isDr = invoiceName?.startsWith('Dr.');
  //   // console.log("2--->", isDr);
  //   const result = isDr ? invoiceName.slice(4).trim() : invoiceName;
  //   // console.log("3--->", result);
  //   const nameParts = result.split(' ');
  //   // console.log("4--->", nameParts);
  //   const firstName = nameParts.length > 0 ? nameParts[0] : '';

  //   async function formatInvoiceJobDate(rawDate) {
  //     if (!rawDate) {
  //       return null;
  //     }

  //     const dateObject = new Date(rawDate);
  //     const day = String(dateObject.getDate()).padStart(2, '0');
  //     const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  //     const year = dateObject.getFullYear();

  //     return `${day}_${month}_${year}`;
  //   }

  //   // console.log("invoiceData>>",invoiceData)
  //   // console.log("1-->",invoiceData?.job?.consequtiveTime)
  //   // console.log("2--->",invoiceData?.job?.nonConsecutiveTime?.start?.datetime)
  //   // const invoiceJobDate = invoiceData?.job?.consequtiveTime?.start?.datetime || invoiceData?.job?.nonConsequtiveTime?.start?.datetime;
  //   const invoiceJobDate = invoiceData?.shiftDate
  //   // console.log(">!>", invoiceJobDate)
  //   const formattedDate = await formatInvoiceJobDate(invoiceJobDate);
  //   // console.log("6-->", formattedDate);
  //   const lastPart = invoiceData?.invoiceNumber.split("/").pop();
  //   const fileName = `${firstName}_${formattedDate}_${lastPart}`;
  //   // console.log("Invoice", fileName);
  //   return fileName
  // }

  // Call the function with invoiceData[0]
  //  const finalInvoice =  await processInvoiceData(invoiceData[0]);
  //  console.log("Invoice", finalInvoice);

  const lastPart = invoiceTaxData[0]?.invoiceNumber.split("/").pop();
  async function printDocument() {
    let jsPdf = new jsPDF("p", "pt", "letter");
    var htmlElement = document.getElementById("invoiceContainer1");
    const opt = {
      callback: function (jsPdf) {
        jsPdf.save(`${lastPart}.pdf`);
        window.open(jsPdf.output("bloburl"));
      },
      margin: 10,
      autoPaging: "text",
      html2canvas: {
        allowTaint: true,
        dpi: 300,
        letterRendering: true,
        logging: false,
        scale: 0.45,
      },
    };

    jsPdf.html(htmlElement, opt);
  }

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox m={2}>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <div style={{ display: "flex", width: "70px" }}>
              <Link onClick={handleBack}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIos fontSize="small" />
                  back
                </div>
              </Link>
            </div>

            <MDButton
              variant="contained"
              color="info"
              onClick={() => {
                printDocument();
              }}
            >
              Export as PDF
            </MDButton>
          </Grid>
        </MDBox>
        <div id="invoiceContainer1">
          <Paper sx={{ marginTop: "1rem" }}>
            <MDBox className={"invoiceBox"}>
              <div className="invoiceHeader">
                <img src={IfanFlexLogo} alt="logo" className="logoStyle" />

                <div className="headerContent">
                  <h4>Duplicate Copy</h4>
                  <br />
                  <small>Plot No.89, 3Floor, Sector 44</small>
                  <br />
                  <small>Gurgaon - 122003, Haryana (India)</small>
                  <br />
                  <small>Email: info@ifanglobal.com</small>
                  <hr />
                  <small>Reg. Office: H-294, Plot 2A, First Floor,</small>
                  <br />
                  <small>Singh Estate, Saidulajab, Lane No. 2,</small>
                  <br />
                  <small>Saket Delhi New Delhi DI 110030 In</small>
                </div>
              </div>
              <br />
              <br />

              <table>
                <tbody>
                  <tr>
                    <th
                      colSpan={3}
                      style={{
                        backgroundColor: "lightgrey",
                        textAlign: "center",
                      }}
                    >
                      TAX INVOICE
                    </th>
                  </tr>
                  <tr>
                    <th>Service Provider</th>
                    <th>Invoice No.</th>
                    <th>Dated</th>
                  </tr>
                  <tr>
                    <td>IFAN Flex Pvt. Ltd.</td>
                    <td>{invoiceTaxData[0]?.invoiceNumber}</td>
                    <td>
                      {moment(invoiceTaxData[0]?.createdAt).format(
                        "DD-MM-YYYY"
                      )}{" "}
                      &nbsp; &nbsp; &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Works:Plot No 89, 3rd Floor, Sector 44 Gurugram <br />
                      122003
                    </td>
                    <td colSpan={2}>
                      Tax Registration
                      <br />
                      PAN:AAFCI9183G
                      <br />
                      GSTN:06AAFCI9183G1ZB
                      <br />
                      CIN:U74999DL2020PTC373866
                    </td>
                  </tr>
                  <tr>
                    <th>Buyer:</th>
                    <th colSpan={2}>Instructions:</th>
                  </tr>
                  <tr>
                    <td>
                      {invoiceTaxData[0]?.userDetails?.nameAsPerPan || "NA"} &nbsp;
                      <br />
                      {invoiceTaxData[0]?.userDetails?.address?.address || "NA"}
                      <br />
                      {invoiceTaxData[0]?.userDetails?.address?.state || "NA"}
                    </td>

                    <td rowSpan={2} colSpan={2}>
                      IfanFlex Pvt. Ltd
                      <br />
                      ICICI Bank Ltd.
                      <br />
                      Account No. : 402105000512
                      <br />
                      Branch: NDSE-I, South Extension New Delhi-110049
                      <br />
                      IFSC:ICIC0004021
                    </td>
                  </tr>
                  <tr>
                    <td>
                      State Code:{" "}
                      {invoiceTaxData[0]?.stateCode
                        ? invoiceTaxData[0]?.stateCode
                        : "NA"}
                      <br />
                      Reverse charges applicable:{" "}
                      {invoiceTaxData[0]?.reverseChargesApplicable
                        ? invoiceTaxData[0]?.reverseChargesApplicable
                        : "NA"}
                      <br />
                      GSTIN:{" "}
                      {invoiceTaxData[0]?.GSTIN
                        ? invoiceTaxData[0]?.GSTIN
                        : "NA"}
                    </td>
                  </tr>
                  <tr>
                    <th className="textAlignmentCenter">S.no.</th>
                    <th className="textAlignmentCenter">
                      Description of Service
                    </th>
                    <th className="textAlignmentCenter">Amount</th>
                  </tr>

                  {invoiceTaxData[0]?.invoices?.length > 0 ? (
                    invoiceTaxData[0].invoices.map((invoice, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td className="textAlignmentCenter">{index + 1}</td>
                          <td className="textFit">
                            Platform Fee Against Job Id {invoice.jobId}
                          </td>
                          <td className="textAlignmentRight">
                            {invoice.convenienceFee
                              ? invoice.convenienceFee.toFixed(2)
                              : -invoice?.costSaved?.toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td className="textAlignmentCenter"></td>
                          <td className="textFit">
                            Premium Plan Benefit {invoice.jobId}
                          </td>
                          <td className="textAlignmentRight">
                            {invoice?.costSaved
                              ? invoice?.costSaved.toFixed(2)
                              : "0"}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))
                  ) : (
                    <>
                      <tr>
                        <td className="textAlignmentCenter">1</td>
                        <td className="textFit">
                          Platform Fee Against Job Id{" "}
                          {invoiceTaxData[0]?.invoiceDetails?.jobId}
                        </td>
                        <td className="textAlignmentRight">
                          {invoiceTaxData[0]?.totalConvenienceFee ? invoiceTaxData[0]?.totalConvenienceFee.toFixed(2)
                            : "0"}
                        </td>
                      </tr>
                      <tr>
                        <td className="textAlignmentCenter"></td>
                        <td className="textFit">
                          Premium Plan Benefit{" "}
                          {invoiceTaxData[0]?.invoiceDetails?.jobId}
                        </td>
                        <td className="textAlignmentRight">
                          {invoiceTaxData[0]?.totalCostSaved
                            ? invoiceTaxData[0]?.totalCostSaved.toFixed(2)
                            : "0"}
                        </td>
                      </tr>
                    </>
                  )}

                  {/* {invoiceTaxData[0]?.invoices?.map((invoice, index) => (
                    <>
                    <tr key={index}>
                      <td className="textAlignmentCenter">{index + 1}</td>
                      <td className="textFit">
                      Platform Fee Against Job Id {invoice.jobId}
                      </td>
                      <td className="textAlignmentRight">
                        {invoice.convenienceFee ? invoice.convenienceFee.toFixed(2) : -invoice?.costSaved?.toFixed(2)}
                      </td>
                      
                    </tr>
                    <tr key={index}>
                    <td className="textAlignmentCenter">{}</td>
                    <td className="textFit">
                      Premium Plan Benefit {invoice.jobId}
                    </td>
                    <td className="textAlignmentRight">
                      {invoice?.costSaved ? invoice?.costSaved?.toFixed(2) : '0'}
                    </td>
                  </tr>
                  </>
                  ))} */}

                  <tr>
                    <td colSpan={2}>
                      <b>Total Cost Saved</b>
                    </td>
                    <td className="textAlignmentRight">
                      {invoiceTaxData[0]?.totalCostSaved
                        ? -invoiceTaxData[0]?.totalCostSaved?.toFixed(2)
                        : "0"}
                    </td>
                  </tr>

                  {!invoiceTaxData[0]?.cgst || !invoiceTaxData[0]?.sgst ? (
                    <>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: CGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight">{"-"}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: SGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight">{"-"}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: IGST @ 18%</b>
                        </td>
                        <td className="textAlignmentRight">
                          {invoiceTaxData[0]?.gst.toFixed(2)}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: CGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight">
                          {invoiceTaxData[0]?.cgst.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: SGST @ 9%</b>
                        </td>
                        <td className="textAlignmentRight">
                          {invoiceTaxData[0]?.sgst.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <b>Add: IGST @ 18%</b>
                        </td>
                        <td className="textAlignmentRight">{"-"}</td>
                      </tr>
                    </>
                  )}

                  <tr>
                    <td colSpan={2}>
                      <b>Total</b>
                    </td>
                    <td className="textAlignmentRight">
                      {invoiceTaxData[0]?.total.toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="invoiceFooter">
                {/* <b>
                  Note <br /> * TDS to be deducted at @ 2% under 194C
                </b>
                <br /> */}
                <p>Terms & Conditions:</p>
                <div className="invoiceList">
                  {/* <ol> */}
                  <p>1. Subject to 'Delhi' Jurisdiction only</p>
                  <p>
                    2. Service Accounting Code:{" "}
                    {invoiceTaxData[0]?.sac ? invoiceTaxData[0]?.sac : "______"}
                  </p>

                  {/* </ol> */}
                </div>
                <div>
                  <p className="pStyle">For IFAN Flex Pvt. Ltd.</p>
                  <img
                    src={authoritySignature}
                    alt="logosign"
                    className="logosStyle"
                  />
                  <p className="footerText">
                    No Signature required as it's a system generated Invoice
                  </p>
                </div>

                <hr />
                <div className="footerStyle">
                  <p>Office: +91 124.487 5800</p>
                  <p>Fax : +91 124.401 4035</p>
                  <p>CIN: U74999DL2020PTC373866</p>
                </div>
              </div>

              <br />
            </MDBox>
          </Paper>
        </div>
      </DashboardLayout>
    </>
  );
};

export default UserTaxInvoice;
