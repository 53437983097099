// Material Dashboard 2 PRO React layouts
// import Analytics from "layouts/dashboards/analytics";
// import Sales from "layouts/dashboards/sales";

import Icon from "@mui/material/Icon";
import AdditionalTrainings from "layouts/common/AdditionalTrainings";

import AdminUsers from "layouts/dashboards/adminUsers";

import HospitalUsers from "layouts/users/HospitalUsers";
// import Sales from "layouts/dashboards/sales";
import PaymentIcon from "@mui/icons-material/Payment";
import GigBillingDetails from "layouts/billing/GigBillingDetails";
import CompanyType from "layouts/common/CompanyType";
import DepartmentType from "layouts/common/DepartmentType";
import DocumentType from "layouts/common/DocumentType";
import Experience from "layouts/common/Experience";
import JobDetails from "layouts/common/JobDetails";
import Language from "layouts/common/Languages";
import Profession from "layouts/common/Profession";
import PRT from "layouts/common/ProfessionRegisterationType";
import Qualification from "layouts/common/Qualification";
import Softwares from "layouts/common/Softwares";
import Specialisation from "layouts/common/Specialisation";
import Cities from "layouts/common/cities/Cities";
import HomePage from "layouts/dashboards/HomePage";
import Roles from "layouts/dashboards/roles";
import EventListing from "layouts/events/EventListing";
import InterviewListing from "layouts/events/InterviewListing";
import ReferralListing from "layouts/events/ReferralListing";
import HospitalDepartments from "layouts/hospital/HospitalDepartments";
import HospitalRates from "layouts/hospital/HospitalRates";
import HospitalBranch from "layouts/hospital/hospitalBranch";
import HospitalCompany from "layouts/hospital/hospitalCompany";
import HospitalCorporate from "layouts/hospital/hospitalCorporate";
import Applications from "layouts/job/Applications";
import LongTermJobs from "layouts/job/LongTermJobs";
import PaymentTerms from "layouts/transaction/payment terms";
import Payments from "layouts/transaction/payments";
import UserInvoiceDetails from "layouts/userInvoices/UserInvoiceDetails";
import BankDetails from "layouts/userInvoices/bankDetails";
import Jobs from "./layouts/job/Jobs";
import Availability from "layouts/availbility/Availability";
import PermanentApplication from './layouts/permanent/PermanentApplication';
import PermanentJob from './layouts/permanent/PermanentJob';
import Skills from "layouts/common/Skills/Skills";
import DocVerification from "layouts/common/docverification/DocVerification";
import UserTaxInvoiceDetails from "layouts/userInvoices/UserTaxInvoiceDetails"
import LocumCreditLimit from "layouts/transaction/LocumCreditLimit"
import PremiumUser from "layouts/users/PremiumUser"
import MonetizationMLP from "layouts/users/MonetizationMLP"
import MonetizationDiscount from "layouts/users/MonetizationDiscount"
import GCCJob from "layouts/gccJob/GCCJob"
import GCCDepartment from "layouts/common/GCCDepartment"  
import GCCExperience from "layouts/common/GCCExperience"  
import Videos from "layouts/videoManagement/Videos"
import UserVideos from "layouts/videoManagement/UserVideos"


const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "changelog",
    icon: <Icon fontSize="medium">home</Icon>,
    route: "/home",
    noCollapse: true,
    component: <HomePage />,
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Admin",
    key: "dashboards",
    icon: <Icon fontSize="medium">person</Icon>,
    collapse: [
      {
        name: "Admin Users",
        key: "admin",
        icon: <Icon fontSize="small"></Icon>,
        route: "/dashboards/admin",
        component: <AdminUsers />,
      },

      // {
      //   name: "Aris",
      //   key: "aris",
      //   route: "/dashboards/aris",
      //   component: <Aris />,
      // },
      {
        name: "Roles",
        key: "roles",
        route: "/dashboards/roles",
        component: <Roles />,
      },
    ],
  },

  { type: "divider", key: "divider-2" },
  {
    type: "collapse",
    name: "Common",
    key: "common",
    icon: <Icon fontSize="medium">medical_services</Icon>,

    collapse: [
      {
        name: "Job Description",
        key: "jobDescription",
        route: "/common/jobDescription",
        component: <JobDetails />,
      },
      {
        name: "Additional Trainings",
        key: "additionalTrainings",
        route: "/common/additionalTrainings",
        component: <AdditionalTrainings />,
      },
      {
        name: "Cities",
        key: "cities",
        route: "/common/cities",
        component: <Cities />,
      },
      {
        name: "Company Types",
        key: "companyTypes",
        route: "/common/companyTypes",
        component: <CompanyType />,
      },
      {
        name: "Department Types",
        key: "departmentTypes",
        route: "/common/departmentTypes",
        component: <DepartmentType />,
      },
      {
        name: "Document Types",
        key: "documentTypes",
        route: "/common/documentTypes",
        component: <DocumentType />,
      },
      {
        name: "Languages",
        key: "languages",
        route: "/common/languages",
        component: <Language />,
      },
      {
        name: "Experience",
        key: "experience",
        route: "/common/experience",
        component: <Experience />,
      },
      {
        name: "Specialization",
        key: "specialization",
        route: "/common/specialization",
        component: <Specialisation />,
      },
      {
        name: "Professional Registration Types",
        key: "registrationTypes",
        route: "/common/registrationTypes",
        component: <PRT />,
      },
      {
        name: "Profession",
        key: "profession",
        route: "/common/profession",
        component: <Profession />,
      },
      {
        name: "Qualification",
        key: "qualification",
        route: "/common/qualification",
        component: <Qualification />,
      },
      {
        name: "Softwares",
        key: "softwares",
        route: "/common/softwares",
        component: <Softwares />,
      },
      {
        name: "Additional Skills",
        key: "additionalskills",
        route: "/common/additionalskills",
        component: <Skills />,
      },

      {
        name: "International Department",
        key: "internationalDepartment",
        route: "/common/internationalDepartment",
        component: <GCCDepartment />,
      },

      {
        name: "International Experience",
        key: "internationalExperience",
        route: "/common/internationalExperience",
        component: <GCCExperience />,
      },

      // {
      //   name: "Document Verification",
      //   key: "documentverifications",
      //   route: "/common/documentverifications",
      //   component: <DocVerification />,
      // },
    ],
  },

  { type: "divider", key: "divider-3" },
  {
    type: "collapse",
    name: "Events",
    key: "events",
    icon: <Icon fontSize="medium">event</Icon>,
    collapse: [
      {
        name: "Events List",
        key: "eventDetails",
        route: "/eventDetails",
        component: <EventListing />,
      },
      {
        name: "Interview",
        key: "interviewDetails",
        route: "/interviewDetails",
        component: <InterviewListing />,
      },
      {
        name: "Referral",
        key: "referralDetails",
        route: "/referralDetails",
        component: <ReferralListing />,
      },
    ],
  },
  { type: "divider", key: "divider-4" },
  {
    type: "collapse",
    name: "Hospital",
    key: "hospital",
    icon: <Icon fontSize="medium">local_hospital</Icon>,
    collapse: [
      {
        name: "Corporate",
        key: "corporate",
        route: "/hospital/corporate",
        component: <HospitalCorporate />,
      },
      {
        name: "Company",
        key: "company",
        route: "/hospital/company",
        component: <HospitalCompany />,
      },

      {
        name: "Branch",
        key: "branch",
        route: "/hospital/branch",
        component: <HospitalBranch />,
      },
      {
        name: "Department",
        key: "department",
        route: "/hospital/department",
        component: <HospitalDepartments />,
      },

      {
        name: "Rates",
        key: "rates",
        route: "/hospital/rates",
        component: <HospitalRates />,
      },
    ],
  },
  { type: "divider", key: "divider-5" },
  {
    type: "collapse",
    name: "Locum Job",
    key: "job",
    icon: <Icon fontSize="medium">work</Icon>,
    collapse: [
      {
        name: "Application",
        key: "application",
        route: "/jobs/application",
        component: <Applications />,
      },
      {
        name: "Locum Jobs",
        key: "jobDetails",
        route: "/jobDetails",
        component: <Jobs />,
      },
      // {
      //   name: "Permanent Jobs",
      //   key: "longtermjobs",
      //   route: "/jobs/permanentJobs",
      //   component: <LongTermJobs />,
      // },
    ],
  },
  { type: "divider", key: "divider-6" },
  {
    type: "collapse",
    name: "Permanent Job",
    key: "permanent",
    icon: <Icon fontSize="medium">work</Icon>,
    collapse: [
      {
        name: "Application",
        key: "application",
        route: "/permanent/application",
        component: <PermanentApplication />,
      },
      {
        name: "Job",
        key: "Job",
        route:"/permanent/job",
        component: <PermanentJob />,
      },
    ],
  },
  { type: "divider", key: "divider-6" },
  // {
  //   type: "collapse",
  //   name: "International Job",
  //   key: "international",
  //   icon: <Icon fontSize="medium">work</Icon>,
  //   collapse: [
  //     // {
  //     //   name: "Application",
  //     //   key: "application",
  //     //   route: "/permanent/application",
  //     //   component: <PermanentApplication />,
  //     // },
  //     {
  //       name: "International",
  //       key: "international",
  //       route:"/international/job",
  //       component: <GCCJob />,
  //     },
  //   ],
  // },
  // { type: "divider", key: "divider-transaction" },
  {
    // type: "collapse",
    // name: "Transaction",
    // key: "transaction",
    type: "collapse",
    name: "CreditLimit",
    key: "creditLimit",
    icon: (
      <Icon fontSize="medium">
        <PaymentIcon />
      </Icon>
    ),
    collapse: [
      // {
      //   name: "Billers",
      //   key: "billers",
      //   route: "/transaction/billers",
      //   component: <Billers />,
      // },
      // {
      //   name: "Invoices",
      //   key: "invoices",
      //   route: "/transaction/invoices",
      //   component: <Invoices />,
      // },
      

      {
        name: "CreditLimit",
        key: "creditLimit",
        route: "/creditLimit/creditLimit",
        component: <LocumCreditLimit />,
      },

      // {
      //   name: "Orders",
      //   key: "order",
      //   route: "/transaction/order",
      //   component: <Payments />,
      // },
      // {
      //   name: "Transactions",
      //   key: "paymentTerms",
      //   route: "/transaction/transactions",
      //   component: <PaymentTerms />,
      // },
    ],
  },
  { type: "divider", key: "divider-logs" },
  {
    type: "collapse",
    name: "Users",
    key: "Users",
    icon: <Icon fontSize="medium">group</Icon>,

    collapse: [
      {
        name: "Nurse/Hospital User",
        key: "hospitalUser",
        route: "/users",
        component: <HospitalUsers />,
      },
      
      {
        name: "Premium User",
        key: "premiumuser",
        route: "/users/premiumuser",
        component: <PremiumUser />,
      },
      {
        name: "Document Verification",
        key: "documentverifications",
        route: "/users/documentverifications",
        component: <DocVerification />,
      },
      
    ],
  },
  { type: "divider", key: "divider-6" },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="medium">receipt_long</Icon>,
    collapse: [
      {
        name: "Locum Bills",
        key: "locumBill",
        route: "/locumBill",
        component: <GigBillingDetails />,
      },
    ],
  },
  { type: "divider", key: "divider-7" },
  {
    type: "collapse",
    name: "Locum Invoices",
    key: "userinvoices",
    icon: <Icon fontSize="medium">receipt</Icon>,
    collapse: [
      {
        name: "Professional Invoice",
        key: "professionalInvoice",
        route: "/invoiceDetails",
        component: <UserInvoiceDetails />,
      },
      {
        name: "Professional Tax Invoice",
        key: "professionalTaxInvoice",
        route: "/invoiceTaxDetails",
        component: <UserTaxInvoiceDetails />,
      },
      {
        name: "Bank Details",
        key: "bankDetails",
        route: "/bankDetails",
        component: <BankDetails />,
      },
    ],
  },

  { type: "divider", key: "divider-8" },
  {
    type: "collapse",
    name: "Availability",
    key: "availability",
    icon: <Icon fontSize="medium">event</Icon>,

    collapse: [
      {
        name: "Availability",
        key: "availability",
        route: "/availbility",
        component: <Availability />,
      },
    ],
  },
  { type: "divider", key: "divider-9" },
  {
    type: "collapse",
    name: "Monetization ",
    key: "monetization ",
    icon: <Icon fontSize="medium">monetization_on</Icon>,
    
    collapse: [
      {
        name: "Monetization MLP",
        key: "monetizationmlp",
        route: "/users/monetizationmlp",
        component: <MonetizationMLP />,
      },
      // {
      //   name: "Monetization Discount",
      //   key: "monetizationdiscount",
      //   route: "/users/monetizationdiscount",
      //   component: <MonetizationDiscount />,
      // },
    ],
  },
  { type: "divider", key: "divider-10" },
  // {
  //   type: "collapse",
  //   name: "Video Management ",
  //   key: "Video Management ",
  //   icon: <Icon fontSize="medium">monetization_on</Icon>,
    
  //   collapse: [
  //     {
  //       name: "video",
  //       key: "video",
  //       route: "/videoManagement/video",
  //       component: <Videos />,
  //     },
      
  //     {
  //       name: "UserVideos",
  //       key: "uservideos",
  //       route: "/videoManagement/uservideos",
  //       component: <UserVideos />,
  //     },
  //   ],
  // },
];

export default routes;
